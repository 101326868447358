<template>
  <div class="project-list-content flex-column">
      <el-breadcrumb class="breadcrumb-title" separator="/">
            <el-breadcrumb-item>项目管理</el-breadcrumb-item>
            <el-breadcrumb-item>项目列表</el-breadcrumb-item>
        </el-breadcrumb>
    <div class="flex-row-space-between">
      <template v-for="(item, index) in topItemData">
        <TopItem class="top-item-wrapper" v-bind:key="'topItem' + index" :data="item"/>
      </template>
    </div>
    <div class="content-wrapper flexcolumn-row-center">
      <div class="search-wrapper flexrow-column-center">
        <SearchInput class="search-input"
         style="width:300px"
          placeholderStr="请输入项目名称"
          @updateSearchList="updateSearchList"
        />
        <span class="font-size-14">所有者：</span>
        <SearchDropDown class="search-input"
        style="width:300px"
          :options="userList"
          @dropDownSelect="dropDownSelect"
          />
      </div>
      <PaginationList
        type="project"
        :title="titleList"
        :list="searchList"
        :totalLength="topItemData[0].value"
        :isNeedOperation="[1,1,1]"
        :projectDetail="projectDetail"
        @onOperation='onOperation'/>
    </div>
    <ProjectEditor v-if="isShowProjectEditor"
      :options="userList"
      :projectName="curProjectData.name"
      :projectkey="curProjectData.user_id+''"
      @onCancel='onProjectEditorCancel'
      @onConfirm='onProjectEditorConfirm'
      />
  </div>
</template>

<script>
import request from '@request/api/index'
import TopItem from './components/TopItem'
import PaginationList from '@components/PaginationList'
import SearchInput from '@components/SearchInput'
import SearchDropDown from '@components/SearchDropDown'
import ProjectEditor from './components/ProjectEditor'

export default {
  name: 'ProjectList',
  data () {
    return {
      topItemData: [{ bgColor: [52, 104, 254], iconColor: '#3468FE', des: '全部项目', value: 0, icon: 'iconxiangmu' },
        { bgColor: [255, 153, 0], iconColor: '#FFA925', des: '全部项目成员', value: 0, icon: 'iconyuangongguanli' },
        { bgColor: [49, 207, 208], iconColor: '#31CFD0', des: '全部文件数', value: 0, icon: 'iconwenjian' },
        { bgColor: [88, 103, 195], iconColor: '#5867C3', des: '视频数', value: 0, icon: 'iconshipin' }],
      userList: [{ key: '', value: '全部' }], // 所有者列表
      itemList: [], // 项目列表
      searchList: [], // 过滤后的项目列表
      titleList: [{ key: 'obj', value: '项目名称' },
        { key: 'created_at', value: '创建时间' },
        { key: 'storage_count', value: '空间占用' },
        { key: 'file_count', value: '文件数量' },
        { key: 'updated_at', value: '更新时间' }],

      ownUserId: '', // 所有者user_id
      searchInputText: '',
      curProjectData: {}, // 当前选中的项目数据
      isShowProjectEditor: false,
      projectDetail: {} // 项目数据详情
    }
  },
  created () {
    this.requestProjectInit()
    this.requestProjectUsers()
    this.requestProjectItems()
  },
  methods: {
    /**
     * 获取项目列表初始化数据
     */
    async requestProjectInit () {
      try {
        const res = await request.project.requestProjectInit()
        this.topItemData[0].value = res.data.data.count
        this.topItemData[1].value = res.data.data.users
        this.topItemData[2].value = res.data.data.files
        this.topItemData[3].value = res.data.data.videos
      } catch (err) {
        console.log(err)
      }
    },
    /**
     * 获取下拉筛选--所有者
     */
    async requestProjectUsers () {
      try {
        const res = await request.project.requestProjectUsers()
        const { user_list } = res.data.data
        for (var key in user_list) {
          this.userList.push({ key: key, value: user_list[key] })
        }
      } catch (err) {
        console.log(err)
      }
    },
    /**
     * 请求获取项目列表
     */
    async requestProjectItems () {
      try {
        const res = await request.project.requestProject()
        this.itemList = res
        this.searchList = JSON.parse(JSON.stringify(res))
      } catch (err) {
        console.log(err)
      }
    },
    /**
     * 获取项目详情
     */
    async requestProjectDetail(cb) {
      try{
        const params = { project_id: this.curProjectData.project_number }
        const res = await request.project.requestProjectDetail(params)
        this.projectDetail = res.data.data
        if(cb) cb()
      }catch(err) {
        console.log(err)
      }
    },
    /**
     * 请求删除项目
     */
    async requestProjectDelete(project_id) {
      try{
        const params = { project_id }
        await request.project.requestProjectDelete(params)
        for(let i = 0;i < this.itemList.length;i++) {
          if(this.itemList[i].obj.project_number === project_id) {
            this.itemList.splice(i, 1)
          }
        }
        for(let i = 0;i < this.searchList.length;i++) {
          if(this.searchList[i].obj.project_number === project_id) {
            this.searchList.splice(i, 1)
          }
        }
        this.$message.success('删除成功！')
        this.requestProjectInit()
      }catch(err) {
        console.log(err)
      }
    },
    /**
     * 编辑项目
     */
    async requestProjectEditor(data) {
      try{
        const params = { project_id: this.curProjectData.project_number,
          project_name: data.name, user_id: data.region }
        await request.project.requestProjectEditor(params)
        for(let i = 0;i < this.itemList.length;i++) {
          if(this.itemList[i].obj.project_number === params.project_id) {
            this.itemList[i].obj.name = data.name
            this.itemList[i].obj.user_name = this.getUserName(data.region)
            this.itemList[i].obj.user_id = data.region
          }
        }
        this.filterData()
        this.$message.success('编辑成功！')
        this.isShowProjectEditor = false
      }catch(err) {
        console.log(err)
      }
    },
    getUserName(user_id) {
      for(let i = 0;i < this.userList.length;i++) {
        if(this.userList[i].key == user_id) {
          return this.userList[i].value
        }
      }
    },
    /**
     * 搜索结果回调
     */
    updateSearchList(str) {
      this.searchInputText = str
      this.filterData()
    },
    /**
     * 所有者选择回调
     */
    dropDownSelect(id) {
      this.ownUserId = id
      this.filterData()
    },
    /**
     * 操作相关类型点击回调
     */
    onOperation(data) {
      this.curProjectData = data.subData.obj
      console.log('data===>',data,this.userList);
      switch(data.type) {
        case 0:
          this.requestProjectDetail(data.cb)
          break;
        case 1:
          this.isShowProjectEditor = true
          break;
        case 2:
          this.showDeleteMessageBox(data.subData)
          break;
      }
    },
    /**
     * 显示删除确认弹窗
     */
    showDeleteMessageBox(data) {
      this.$confirm(`确认删除“${data.obj.name}”吗，删除后项目内资料将全部清空。`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.requestProjectDelete(data.obj.project_number)
      }).catch(() => {
        this.$message.warning('已取消删除')
      })
    },
    onProjectEditorCancel() {
      this.isShowProjectEditor = false
    },
    onProjectEditorConfirm(data) {
      this.requestProjectEditor(data)
    },
    /**
     * 对项目进行过滤
     */
    filterData() {
      let copyList = JSON.parse(JSON.stringify(this.itemList))
      let arr = []
      for(let i = 0;i<copyList.length;i++) {
        if((this.ownUserId == '' || copyList[i].obj.user_id == this.ownUserId)
          && copyList[i].obj.name.indexOf(this.searchInputText) >= 0) {
          arr.push(copyList[i])
        }
      }
      this.searchList = arr
    }
  },
  components: {
    TopItem, PaginationList, SearchInput, SearchDropDown, ProjectEditor
  }
}
</script>

<style lang="scss" scoped>
.project-list-content {
  width: 100%;
  min-width: 1184px;
  height: 100%;
  .top-item-wrapper{
    width:25%;
    height:0;
    margin-right:24px;
    padding-bottom: 10.135%;
    background: white;
    border-radius: 8px;
    position: relative;
  }
  .top-item-wrapper:last-child{
    margin-right:0px;
  }
  .content-wrapper{
    flex:1;
    min-width: 1184px;
    background: white;
    min-height: 770px;
    border-radius: 8px;
    margin-top:20px;
    .search-wrapper{
      width:95%;
      min-width: 1120px;
      height:64px;
      .search-input{
        margin-right:30px;
        width:224px;
        height:32px;
      }
    }
    .pagination-list-wrapper{
      position: relative;
      flex:1;
      width:95%;
      min-width: 1120px;
    }
  }
}

</style>